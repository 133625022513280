.root {
  display: grid;
  place-items: center;
  background: linear-gradient(165deg, #f7f7f7 50%, #fdfdfd 50%);
  min-height: 100vh;
  gap: 20px;
  padding: 20px;
  position: relative;
  overflow-x: hidden;
}

.root::after {
  content: '';
  position: absolute;
  background: linear-gradient(90deg, #ca1145 24%, transparent 24%, transparent 75%, #7cb974 75%);
  height: 25px;
  width: 100%;
  z-index: 0;
  mix-blend-mode: multiply;
  overflow: hidden;
  transform: translate(-3px, -4px) rotate(-16deg) scale(1.5) skew(-32deg, 0deg);
}

.root::before {
  content: '';
  position: absolute;
  background: linear-gradient(90deg, #226d91 24%, transparent 24%);
  height: 25px;
  width: 100%;
  z-index: 0;
  mix-blend-mode: multiply;
  overflow: hidden;
  transform: translate(-75px, -4px) rotate(-16deg) scale(1.5) skew(-32deg, 0deg);
}

.App-header {
  place-items: start;
  place-content: start;
}

.App-header a,
.App-header img {
  width: 250px;
}

.App {
  width: 100%;
  max-width: 650px;
  display: grid;
  gap: 20px;
  z-index: 1;
}

.App>main {
  display: grid;
  background: #fff;
  padding: 40px;
  box-shadow: 0 6px 12px -2px rgba(50, 50, 93, 0.25), 0 3px 7px -3px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  gap: 20px;
  width: 100%;
}

.App>main .lookup {
  display: grid;
  gap: 20px;
}

.App>main .lookup input {
  padding: 10px;
}

.App>main .lookup input[type='tel'] {
  padding: 10px 10px 10px 48px;
  width: 100%;
  height: auto;
}

.start-over-link {
  text-align: center;
  font-size: 13px;
  color: #1760ce;
  text-decoration: none;
  font-weight: 500;
}

.submit-btn {
  padding: 20px;
  color: #fff;
  background-color: mediumseagreen;
  text-decoration: none;
  border-radius: 5px;
  box-shadow: 0 3px 6px -1px rgba(50, 50, 93, 0.25), 0 1.5px 3.5px -1.5px rgba(0, 0, 0, 0.3);
  text-align: center;
  font-size: 18px;
  font-weight: 500;
}

.contribution-btn {
  padding: 20px;
  color: #fff;
  background-color: hsl(218deg 75% 46%);
  text-decoration: none;
  border-radius: 5px;
  box-shadow: 0 3px 6px -1px rgba(50, 50, 93, 0.25), 0 1.5px 3.5px -1.5px rgba(0, 0, 0, 0.3);
  text-align: center;
  font-size: 18px;
  font-weight: 500;
}

.cols-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: start;
  gap: 40px;
}

.member-page section {
  display: grid;
  gap: 20px;
  padding: 0 0 20px;
}

.member-page section:not(:last-of-type) {
  border-bottom: 1px solid #ccc;
}

.contributionAmounts {
  gap: 40px;
}

.ContributionTotals {
  justify-content: center;
}

.App>main.transaction-page article {
  place-items: center;
  margin-top: 40px;
  padding: 50px;
  border-radius: 5px;
  box-shadow: inset 0 6px 12px -2px rgb(50 50 93 / 25%), inset 0 3px 7px -3px rgb(0 0 0 / 30%);
}

ul#multiple-member-list input {
  visibility: hidden;
  width: 0;
  padding: 0;
  margin: 0;
}

ul#multiple-member-list label {
  display: flex;
  align-items: flex-start;
  box-shadow: 0 2px 6px -1px rgba(50, 50, 93, 0.25), 0 1.5px 3.5px -1.5px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  padding: 15px;
  background: #ffffff;
  position: relative;
  cursor: pointer;
}

ul#multiple-member-list {
  list-style-type: none;
  display: grid;
  gap: 20px;
  padding: 0;
  margin: 15px 0;
  margin-inline: 0;
  grid-template-columns: 1fr 1fr;
}

ul#multiple-member-list li {
  padding: 0;
  margin: 0;
  margin-inline: 0;
  height: 100%;
  display: grid;
  grid-template-rows: 0 100%;
}

ul#multiple-member-list .memberDetails p {
  font-weight: 400;
}

ul#multiple-member-list .memberDetails p:first-of-type {
  margin-bottom: 5px;
  font-weight: bold;
}

ul#multiple-member-list input:checked+label {
  background: #f5f5f5;
}

ul#multiple-member-list input:checked+label::after {
  content: '✓';
  position: absolute;
  top: 10px;
  right: 10px;
  width: 25px;
  height: 25px;
  color: #fff;
  background: #216a8c;
  border-radius: 50%;
  display: grid;
  place-items: center;
  place-content: center;
  box-shadow: 0 2px 6px -1px rgba(50, 50, 93, 0.25), 0 1.5px 3.5px -1.5px rgba(0, 0, 0, 0.3);
  text-shadow: 1px 1px 8px black;
}

ul#multiple-member-list label:hover {
  background: #f5f5f5;
}

.contrib-stats-right dt {
  width:100px;
}